<template>
    <div class="w-full">
        <div v-if="table.isLoading" class="shipments-list-loading">
            <app-spinner class="shipments-list__loading" />
        </div>
        <div v-else class="shipments-list table-fixed border-radius">
            <div v-for="item in table.visibleRows" :key="item.title" class="shipments-list__item" @dblclick="goTo(item.id)">
                <div class="shipments-list-item customs-list-item border-radius--l">
                    <div class="customs-list-item__col" :style="setWidth(100)">
                        <span class="f-w-700 f-size-16 truncate">
                            {{ item.customs_declaration_id || '–' }}
                        </span>
                        <span :style="`color: ${statusColorMap.get(item.status)}`" class="f-w-medium truncate">
                            {{ item.status }}
                        </span>
                    </div>
                    <div class="customs-list-item__col" :style="setWidth(140)">
                        <span class="customs-list-item__title">
                            Registration date:
                        </span>
                        <span class="f-w-700 truncate">
                            {{ item.date }}
                        </span>
                    </div>
                    <div class="customs-list-item__col" :style="setWidth(125)">
                        <span class="customs-list-item__title">
                            Waybill number:
                        </span>
                        <div class="f-w-700 truncate">
                            {{ item.waybill_number }}
                        </div>
                    </div>
                    <div class="customs-list-item__col" :style="setWidth(160)">
                        <span class="customs-list-item__title">
                            Importer
                        </span>
                        <span class="f-w-700">
                            -
                        </span>
                    </div>
                    <div class="customs-list-item__col" :style="setWidth(60)">
                        <span class="customs-list-item__title">
                            HS code:
                        </span>
                        <span v-if="!hsCodes(item).length" class="f-w-700">–</span>
                        <Hint v-else>
                            <template #trigger>
                                <span class="f-w-700" style="text-decoration: underline; text-decoration-style: dashed; color: #2E457C;">
                                    {{ hsCodes(item)?.length }} codes
                                </span>
                            </template>
                            <template #content>
                                <div>
                                    <div v-for="code in hsCodes(item)" :key="code">
                                        {{ code }}
                                    </div>
                                </div>
                            </template>
                        </Hint>
                    </div>
                    <div class="customs-list-item__col" :style="setWidth(180)">
                        <span class="customs-list-item__title">
                            Accompanying shipments:
                        </span>
                        <span class="f-w-700">
                            <a :href="`/transport/shipments/${item.consignment_uuid}`" target="_blank" class="truncate">
                                {{ item.consignment_number }}
                            </a>
                        </span>
                    </div>
                    <div class="customs-list-item__delimeter" />
                    <div class="customs-list-item__col customs-list-item__col--files truncate">
                        <button
                            v-for="file in item.files.slice(0, 3)"
                            :key="file.id"
                            class="flex items-center cursor-pointer"
                            :title="file.name"
                            @click="downloadFile(file.url, file.file_name)"
                        >
                            <svg-importer icon-name="icons/document" class="mr-2" width="20px" :style="setWidth(20)" />
                            <span class="truncate">
                                {{ getFileNameShort(file.file_name) }}
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import Hint from '@/components/customs/hint.vue';
import tableConfig from '~/components/vtable/config';
import AppSpinner from '~/components/AppSpinner';
import mixinDownloadFile from '~/mixins/downloadFile.mixin';

export default {
    mixins: [tableConfig, mixinDownloadFile],
    components: {
        Hint,
        AppSpinner,
    },
    data() {
        return {
            data: [],
            statusColorMap: new Map([
                ['Done', '#25AA10'],
                ['Review', '#E39E0A'],
                ['Rejected', '#EB5757']
            ]),
        };
    },
    methods: {
        goTo(id) {
            window.open(`/customs/${id}`, '_blank');
        },
        getFileNameShort(name) {
            return name.length > 10 ? name.substring(0, 11) + '...' : name;
        },
        hsCodes(item) {
            return item.orders.map(order => order.items.map(item => item.hs_code)).flat().filter(el => !!el);
        },
        setWidth(width) {
            return {
                width: `${width}px`,
                maxWidth: `${width}px`,
                minWidth: `${width}px`,
            }
        }
    },
    
};
</script>
<style lang="scss">
@import 'src/styles/pages/shipments-list.scss';
@import 'src/styles/pages/shipments-list-items.scss';

.shipments-list {
    height: calc(100vh - 60px);
}

.shipments-list-item {
    min-height: unset;
}

.customs-list-item {
    gap: 24px;
    padding-left: 16px;

    &__title {
        color: #6A7884;
    }

    &__col {
        display: flex;
        flex-direction: column;

        &--files {
            display: flex;
            flex-wrap: wrap;
            flex-grow: 2;
            flex-direction: row !important;

            button {
                width: 50%;
                padding-right: 8px;
            }
        }

        svg {
            path {
                fill: #2E457C;
            }
        }
    }

    &__delimeter {
        width: 2px;
        height: 50px;
        background: #F7F7F7;
    }
}
</style>